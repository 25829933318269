import React, { Component } from 'react';

import Page404 from 'components/Page404'

export default class NotFound extends Component {

  render() {
    return (
      <Page404 />
    );
  }
}
