import React, { PureComponent } from 'react';
import SEO from 'components/seo';
import { Container } from 'components/layout';

import PropTypes from 'prop-types';

import { TimelineLite, TweenLite } from 'gsap';
import s from './Page404.scss';

export default class Page404 extends PureComponent {

  componentDidMount() {
    const timeline = new TimelineLite();

    const video = document.getElementsByClassName(s.video)[0];
    const textItems = document.getElementsByClassName(s.textInfo)[0].childNodes;

    timeline.staggerFromTo(textItems, 1, { autoAlpha: 0, y: 40 }, { autoAlpha: 1, y: 0 }, 0.2);
    timeline.add(TweenLite.fromTo(video, 0, { opacity: 0 }, { opacity: 1, onComplete: () => video.play() }));
  }

  render() {

    return (
      <div className={s.container}>
        <SEO title='Page not found' />
          <Container>
            <div className={s.textInfo}>
              <h1>Page not found</h1>
          </div>
        </Container>
        <div className={s.videoContainer}>
          <video className={s.video} loop="loop" muted>
            <source src="/video/dog-video.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    );
  }
}
